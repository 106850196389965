(function (global, factory) {
	typeof exports === 'object' && typeof module !== 'undefined' ? module.exports = factory() :
	typeof define === 'function' && define.amd ? define(factory) :
	global.Ractive.transitions.fly = factory();
}(this, function () { 'use strict';

	var DEFAULTS = {
		duration: 400,
		easing: 'easeOut',
		opacity: 0,
		x: -500,
		y: 0
	};

	function addPx(num) {
		if (num === 0 || typeof num === 'string') {
			return num;
		}

		return num + 'px';
	}
	function fly(t, params) {
		params = t.processParams(params, DEFAULTS);

		var x = addPx(params.x);
		var y = addPx(params.y);

		var offscreen = {
			transform: 'translate(' + x + ',' + y + ')',
			opacity: 0
		};

		var target = undefined;

		if (t.isIntro) {
			// animate to the current style
			target = t.getStyle(['opacity', 'transform']);

			// set offscreen style
			t.setStyle(offscreen);
		} else {
			target = offscreen;
		}

		t.animateStyle(target, params).then(t.complete);
	}

	return fly;

}));